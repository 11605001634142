exports = module.exports = require("../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EzCM0QwY6Uot16SQtBTjd {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n\n._1YuzWBd0vOdhbPuBI5Ly_V {\n    font-family: monospace;\n    font-size: 12px;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n}\n", ""]);

// exports
exports.locals = {
	"fixedTable": "EzCM0QwY6Uot16SQtBTjd",
	"bodyPreview": "_1YuzWBd0vOdhbPuBI5Ly_V"
};